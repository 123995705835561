body {
  width:100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.root {
  width: 100%;
}

div {
  height: 100vh;
  width: 100%;
  align-content: center;
  margin: auto;
  background-color: whitesmoke;
  overflow-y: auto;
  overflow-x: hidden;
}

img {
  width: 80%;
}

h1 {
  font-size: 60px;
}

h2 {
  text-align: left;
  font-size: 30px;
  padding-left: 30px;
  margin-bottom: 12px;
}

description {
  font-size: 40px;
}

h3 {
  padding-left: 75px;
  font-size: 25px;
  text-align: left;
  margin-bottom: 4px;
}

h4 {
  font-size: 23px;
  margin-bottom: 4px;
}

p {
  width: 80%;
  line-height: 1.5;
  margin: auto;
  font-size: 20px;
  padding: 10px;
}