.article {
    width: 80%;
    height: 100%;
    background-color: white;
}

@media only screen and (max-width:600px)  {
    .article{
        width: 100%;
    }  

    h1 {
        font-size: 40px;
      }
      
      h2 {
        text-align: center;
        font-size: 25px;
        padding-left: 0px;
        margin-bottom: 4px;
      }
      
      description {
        font-size: 20px;
      }
      
      h3 {
        font-size: 15px;
        text-align: center;
        margin-bottom: 4px;
        padding-left: 0px;
      }

      h4 {
          font-size: 14px;
      }

      p {
          font-size: 15px;
      }
}

.article-container {
    display: flex;
    flex-direction: column;
}

.titleImage {
    width: 100%;
    margin: auto;
}

.credit {
    height: auto;
    background-color: white;
    text-align: left;
    width: 80%;
    font-size: 8px;
    border-bottom: 1px;
    border-color: black;
    padding-bottom: 5px;
}

.adsbygoogle{
    display: inline-block;
    width: 728px !important;
    height: 90px !important;
}

@media only screen and (max-width:600px)  {
    .adsbygoogle{
        display: inline-block;
        width: 300px !important;
        height: 50px !important;
    }  
}

.ad-container {
    height: auto;
}